import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorsModel } from '@app/store/user-profile';
import { catchError, Observable, share, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private httpClient: HttpClient) {}

    login(email: string, password: string): Observable<unknown> {
        return this.httpClient.skipRefreshToken().post('auth/login', {
            email,
            password,
        });
    }

    logout(): Observable<unknown> {
        return this.httpClient.post('auth/logout', {});
    }

    resetPassword(email: string): Observable<unknown> {
        return this.httpClient.skipRefreshToken().post('user/password-reset', { email });
    }

    changePassword(password: string, passwordConfirm: string, resetToken: string): Observable<unknown> {
        return this.httpClient
            .skipRefreshToken()
            .patch('user/password-reset', { password, passwordConfirm, resetToken })
            .pipe(catchError((response: HttpErrorResponse) => throwError(() => new ErrorsModel(response.error?.errors))));
    }

    refresh(): Observable<Request> {
        return this.httpClient.skipToken().skipRefreshToken().post<Request>('auth/refresh', {}, { withCredentials: true }).pipe(share());
    }
}
